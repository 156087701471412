(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name alerts.controller:AlertsCtrl
   *
   * @description
   *
   */
  angular
    .module('alerts')
    .controller('AlertsCtrl', AlertsCtrl);

  function AlertsCtrl(Alerts) {
    var vm = this;
    vm.ctrlName = 'AlertsCtrl';
	
	vm.alerts = Alerts.alerts;
	
	vm.close = Alerts.closeAlert;
  }
}());
